import { IComponent } from "@/interfaces/PrizificPageBuilder/IComponent";
import { IProperty } from "@/interfaces/PrizificPageBuilder/IProperty";
import { IImg } from "@/interfaces/PrizificPageBuilder/IImg";
import { EComponents } from "@/enums/PrizificPageBuilder/EComponents";
import AComponent from "../AComponent";
import ASharedComponent from "../ASharedComponent";
import { IVideo } from "~~/interfaces/PrizificPageBuilder/IVideo";

export default class Video
    extends ASharedComponent<EComponents.VIDEO>
    implements IComponent<EComponents.VIDEO>, IVideo
{
    constructor(
        id: string,
        sharedComponentId: string | number | null,
        isSharedComponent: boolean,
        title: string,
        style: Array<IProperty>,
        classNames: string,
        components: Array<IComponent<EComponents>>,
        public videoSrc: string
    ) {
        super(
            id,
            sharedComponentId,
            isSharedComponent,
            title,
            EComponents.VIDEO,
            style,
            classNames,
            components
        );
    }

    toPayload(): { [key: string]: any } {
        return super.toPayload({
            videoSrc: this.videoSrc,
        });
    }
}
