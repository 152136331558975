import { IComponent } from "@/interfaces/PrizificPageBuilder/IComponent";
import { IProperty } from "@/interfaces/PrizificPageBuilder/IProperty";
import { RuntimeComponentParseError } from "@/error/RuntimeError";
import { AComponentFactory } from "../ComponentFactory";
import { EComponents } from "~~/enums/PrizificPageBuilder/EComponents";
import YoutubeVideo from "~~/entities/PrizificPageBuilder/sections/YoutubeVideo";
import Video from "~~/entities/PrizificPageBuilder/components/Video";
import Property from "~~/entities/PrizificPageBuilder/Property";
import { EState } from "~~/enums/PrizificPageBuilder/EState";
import { EProperty } from "~~/enums/PrizificPageBuilder/EProperty";
import { EInputType } from "~~/enums/PrizificPageBuilder/EInputType";
import { ERoles } from "~~/enums/PrizificPageBuilder/ERoles";
import { EUnit } from "~~/enums/PrizificPageBuilder/EUnit";

export class YoutubeVideoFactory extends AComponentFactory<YoutubeVideo> {
    getTypeName() {
        return EComponents.YOUTUBE_VIDEO;
    }

    create(
        rawData: any,
        subComponents: IComponent<any>[],
        properties: IProperty[]
    ) {
        this.validate(rawData, [
            // "data.specific.sectionTitleCompiled",
            // "data.specific.sectionParagraphCompiled",
            // "data.specific.sectionTitle",
            // "data.specific.sectionParagraph",
            // "data.specific.images",
        ]);

        return new YoutubeVideo(
            rawData.id,
            rawData.data.title,
            properties,
            rawData.data.classNames,
            subComponents,
            getConvertedText(rawData.data.specific.sectionTitleCompiled),
            getConvertedText(rawData.data.specific.sectionParagraphCompiled),
            rawData.data.specific.sectionTitle,
            rawData.data.specific.sectionParagraph,
            rawData.data.specific.images
                ? rawData.data.specific.images
                : [
                      {
                          src: "",
                          imageType: "extra-extra-large",
                          imageLabel: "1920px",
                      },
                      {
                          src: "",
                          imageType: "extra-large",
                          imageLabel: "1400px",
                      },
                      {
                          src: "",
                          imageType: "large",
                          imageLabel: "1200px",
                      },
                      {
                          src: "",
                          imageType: "medium",
                          imageLabel: "992px",
                      },
                      {
                          src: "",
                          imageType: "small",
                          imageLabel: "768px",
                      },
                      {
                          src: "",
                          imageType: "extra-small",
                          imageLabel: "576px",
                      },
                  ]
        );
    }

    createPlain() {
        const videoWidth = new Property(
            "Video width",
            128,
            EState.DEFAULT,
            EProperty.WIDTH,
            EInputType.NUMBER,
            [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
            "video",
            EUnit.PX,
            32,
            256
        );

        const videoBorderRadius = new Property(
            "Video border radius",
            0,
            EState.DEFAULT,
            EProperty.BORDER_RADIUS,
            EInputType.NUMBER,
            [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
            "video",
            EUnit.PX,
            0,
            48
        );

        const defaultVideo = new Video(
            "-",
            "",
            false,
            "Youtube video",
            [videoBorderRadius],
            "",
            [],
            ""
        );

        const sectionFontFamily = new Property(
            "Font family",
            "",
            EState.DEFAULT,
            EProperty.FONT_FAMILY,
            EInputType.FONT_SELECT,
            [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
            "wrapper"
        );

        const sectionBackground = new Property(
            "Background color",
            "#fdfcf9",
            EState.DEFAULT,
            EProperty.BACKGROUND_COLOR,
            EInputType.COLOR_PICKER,
            [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
            "wrapper"
        );

        const sectionTitleColor = new Property(
            "Title text color",
            "#444444",
            EState.DEFAULT,
            EProperty.COLOR,
            EInputType.COLOR_PICKER,
            [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
            "title"
        );

        const sectionFontSize = new Property(
            "Title font size",
            32,
            EState.DEFAULT,
            EProperty.FONT_SIZE,
            EInputType.NUMBER,
            [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
            "title",
            EUnit.PX,
            16,
            48
        );

        const sectionParagraphColor = new Property(
            "Paragraph text color",
            "#444444",
            EState.DEFAULT,
            EProperty.COLOR,
            EInputType.COLOR_PICKER,
            [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
            "paragraph"
        );

        const sectionParagraphFontSize = new Property(
            "Paragraph font size",
            16,
            EState.DEFAULT,
            EProperty.FONT_SIZE,
            EInputType.NUMBER,
            [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
            "paragraph",
            EUnit.PX,
            16,
            48
        );

        const swiperPagination = new Property(
            "Swiper pagination color",
            "#444444",
            EState.DEFAULT,
            EProperty.COLOR,
            EInputType.COLOR_PICKER,
            [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
            "swiperPagination"
        );

        const swiperPaginationActive = new Property(
            "Swiper pagination active color",
            "#444444",
            EState.DEFAULT,
            EProperty.COLOR,
            EInputType.COLOR_PICKER,
            [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
            "swiperPaginationActive"
        );

        const swiperArrow = new Property(
            "Swiper arrow color",
            "#444444",
            EState.DEFAULT,
            EProperty.COLOR,
            EInputType.COLOR_PICKER,
            [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
            "swiperArrow"
        );

        const swiperArrowSize = new Property(
            "Swiper arrow size",
            44,
            EState.DEFAULT,
            EProperty.FONT_SIZE,
            EInputType.NUMBER,
            [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
            "swiperArrow",
            EUnit.PX,
            16,
            56
        );

        return new YoutubeVideo(
            "-",
            "Youtube videos",
            [
                sectionFontFamily,
                sectionBackground,
                sectionTitleColor,
                sectionFontSize,
                sectionParagraphColor,
                sectionParagraphFontSize,
                swiperPagination,
                swiperPaginationActive,
                swiperArrow,
                swiperArrowSize,
            ],
            "",
            [defaultVideo],
            getConvertedText("Default title"),
            getConvertedText("Default paragraph"),
            "Default title",
            "Default paragraph"
        );
    }
}
