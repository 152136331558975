import { IComponent } from "@/interfaces/PrizificPageBuilder/IComponent";
import { IProperty } from "@/interfaces/PrizificPageBuilder/IProperty";
import { RuntimeComponentParseError } from "@/error/RuntimeError";
import { AComponentFactory } from "../ComponentFactory";
import { EComponents } from "~~/enums/PrizificPageBuilder/EComponents";

import Video from "~~/entities/PrizificPageBuilder/components/Video";
import Property from "~~/entities/PrizificPageBuilder/Property";
import { EState } from "~~/enums/PrizificPageBuilder/EState";
import { EProperty } from "~~/enums/PrizificPageBuilder/EProperty";
import { EInputType } from "~~/enums/PrizificPageBuilder/EInputType";
import { ERoles } from "~~/enums/PrizificPageBuilder/ERoles";
import { EUnit } from "~~/enums/PrizificPageBuilder/EUnit";

export class VideoFactory extends AComponentFactory<Video> {
    getTypeName() {
        return EComponents.VIDEO;
    }

    create(
        rawData: any,
        subComponents: IComponent<any>[],
        properties: IProperty[]
    ) {
        this.validate(rawData, [
            // "data.specific.sectionTitleCompiled",
            // "data.specific.sectionParagraphCompiled",
            // "data.specific.sectionTitle",
            // "data.specific.sectionParagraph",
            // "data.specific.images",
        ]);

        return new Video(
            rawData.id,
            rawData.sharedComponentId ?? null,
            rawData.isSharedComponent ?? false,
            rawData.data.title,
            properties,
            rawData.data.classNames,
            subComponents,
            rawData.data.specific?.videoSrc ?? "-"
        );
    }

    createPlain() {
        const videoWidth = new Property(
            "Video width",
            128,
            EState.DEFAULT,
            EProperty.WIDTH,
            EInputType.NUMBER,
            [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
            "video",
            EUnit.PX,
            32,
            256
        );

        const videoBorderRadius = new Property(
            "Video border radius",
            0,
            EState.DEFAULT,
            EProperty.BORDER_RADIUS,
            EInputType.NUMBER,
            [ERoles.SMALL_COMPANY, ERoles.LARGE_COMPANY],
            "video",
            EUnit.PX,
            0,
            48
        );

        return new Video(
            "-",
            "",
            false,
            "Youtube video",
            [videoBorderRadius],
            "",
            [],
            ""
        );
    }
}
