import { EComponents } from "~~/enums/PrizificPageBuilder/EComponents";
import { useCampaignDataStore } from "~~/store/campaign/campaignData";

const pagesByComponents: { [key: string]: EComponents } = {
    nyertesek: EComponents.WINNERS_PAGE,
    nyertel: EComponents.WINNER_PAGE,
    sorsolas: EComponents.DRAW_PAGE,
    koszonjuk: EComponents.THANK_YOU_PAGE,
    "felhasznalo-aktivacio": EComponents.USER_ACTIVATION_PAGE,
    "elmultal-tizennyolc": EComponents.AGE_GATE_PAGE,
    "nem-vagy-eleg-idos": EComponents.NOT_ENOUGH_OLD_PAGE,
    "profil-modositas": EComponents.EDIT_PROFILE_PAGE,
    "jelszo-modositas": EComponents.EDIT_PASSWORD_PAGE,
    "feltoltott-kodjaim": EComponents.UPLOADED_CODES_PAGE,
    "kostoltatas": EComponents.KOSTOLTATAS_PAGE,
    "-": EComponents.HOME_PAGE,
};
/**
 * Get the active Page Component by the route.
 */
export function useActualPageType(): EComponents {
    const route = useRoute();
    const campaignDataStore = useCampaignDataStore();

    let page = "-";

    if (route.params.page) {
        page = route.params.page.toString();
    }

    let campaignPrefix = "";
    if (
        campaignDataStore.campaignData &&
        campaignDataStore.campaignData.campaignPrefix != undefined &&
        campaignDataStore.campaignData.campaignPrefix != ""
    ) {
        campaignPrefix = campaignDataStore.campaignData.campaignPrefix;
    }

    if (page == "-" && route.params.slug && campaignPrefix == "") {
        page = route.params.slug.toString();
    } else if (page == "-" && "/" + route.params.slug == campaignPrefix) {
        page = "-";
    }

    if (page && pagesByComponents[page]) {
        return pagesByComponents[page];
    }

    return EComponents.NOT_FOUND;
}

/**
 * Make a link for the campaign ()
 */
export function useDynamicLink(to: string): string {
    if (to.startsWith("http")) {
        return to;
    }

    const campaignDataStore = useCampaignDataStore();

    let target = "";

    if (to == "/") {
        target = campaignDataStore.campaignData.campaignPrefix;
    }

    target = campaignDataStore.campaignData.campaignPrefix + "/" + to;

    return target.replaceAll("//", "/");
}
