export enum EComponents {
    NOT_FOUND = "not-found",
    HOME_PAGE = "home-page",
    AGE_GATE_PAGE = "age-gate-page",
    NOT_ENOUGH_OLD_PAGE = "not-enough-old-page",
    ACTIVATE_REGISTRATION_PAGE = "activate-registration-page",
    THANK_YOU_PAGE = "thank-you-page",
    UPLOADED_CODES_PAGE = "uploaded-codes-page",
    KOSTOLTATAS_PAGE = "kostoltatas-page",//TEMP
    WE_ARE_SORRY_PAGE = "we-are-sorry-page",
    NEW_PASSWORD_PAGE = "new-password-page",
    EDIT_PASSWORD_PAGE = "edit-password-page",
    EDIT_PROFILE_PAGE = "edit-profile-page",
    DRAW_PAGE = "draw-page",
    DRAW = "draw",
    PRIZES_MINION = "prizes-minion",
    DISCOUNT_COUPONS = "discount-coupons",
    WINNERS_PAGE = "winners-page",
    WINNER_PAGE = "winner-page",
    USER_ACTIVATION_PAGE = "user-activation-page",
    FORGOT_PASSWORD_PAGE = "forgot-password-page",
    UPLOADED_CODES = "uploaded-codes",
    UPLOADED_CODES_CARD = "uploaded-codes-card",
    NEW_PASSWORD = "new-password",
    EDIT_PASSWORD = "edit-password",
    EDIT_PROFILE = "edit-profile",
    AGE_GATE = "age-gate",
    NAVBAR = "navbar",
    NAV_ITEM = "nav-item",
    NAV_ITEM_WITH_THREE_STATE = "nav-item-with-three-state",
    LIST_ITEM = "list-item",
    MODAL = "modal",
    IMAGE = "image",
    HERO_IMAGE = "hero-image",
    TIME_INTERVAL = "time-interval",
    PRIZES = "prizes",
    PRIZES_BY_TYPE = "prizes-by-type",
    PRIZES_GROUP_BY_TYPE = "prizes-group-by-type",
    VOTE = "vote",
    VOTE_DEFAULT = "vote-default",
    GAME_STEPS = "game-steps",
    GAME_STEPS_SZENTKIRALYI = "game-steps-szentkiralyi",
    PRIZES_NOTIFICATION = "prizes-notification",
    PROMO_PRODUCTS = "promo-products",
    FAQS = "faqs",
    CODE_UPLOAD = "code-upload",
    REGISTRATION = "registration",
    LOGIN = "login",
    WINNERS = "winners",
    WINNERS_LIST = "winners-list",
    CONTACT = "contact",
    CONTACT_PRIVACY_POLICY = "contact-privacy-policy",
    CONTACT_WITH_PRIVACY_POLICY = "contact-with-privacy-policy",
    BUTTON = "button",
    FOOTER = "footer",
    FOOTER_SMALL = "footer-small",
    FORM = "form",
    FORM_ITEM = "form-item",
    GROUP = "group",
    YOUR_PRIZE = "your-prize",
    CODE_UPLOAD_INFORMATION = "code-upload-information",
    RECIPE_UPLOAD = "recipe-upload",
    SHIPPING_ADDRESS = "shipping-address",
    WINNER_FEEDBACK_PAGE_MESSAGE = "winner-feedback-page-message",
    THANK_YOU_FEEDBACK_PAGE_MESSAGE = "thank-you-feedback-page-message",
    WE_ARE_SORRY_FEEDBACK_PAGE_MESSAGE = "we-are-sorry-feedback-page-message",
    ACTIVATE_REGISTRATION_FEEDBACK_PAGE_MESSAGE = "activate-registration-feedback-page-message",
    IMAGE_TEXT_CONTENT = "image-text-content",
    IMAGE_TEXT = "image-text",
    SWIPE_IMAGE_TEXT_CONTENT = "swipe-image-text-content",
    USER_CONSENTING = "user-consenting",
    TEXT = "text",
    COOKIE_SETTINGS = "cookie-settings",
    YOUTUBE_VIDEO = "youtube-video",
    VIDEO = "video",
    TEXT_BUTTON_SECTION = "text-button-section",
    MAPS = "maps",
}
